import { GALLERY_CONSTS } from 'pro-gallery-lib';
import { experimentsWrapper, window } from '@wix/photography-client-lib';

export class Utils {
  static getBaseUrl(api, viewMode) {
    //
    if (Utils.parseViewMode(viewMode) === GALLERY_CONSTS.viewMode.PREVIEW) {
      return 'https://progallery.wixapps.net';
    } else {
      const baseUrl = api.location.baseUrl;
      const baseUrlParts = baseUrl.split('/');
      const origin = baseUrlParts.slice(0, 3).join('/');
      return origin;
    }
  }

  static getExperiments(baseApi) {
    //
    // only under scope `pro-gallery-viewer`
    const viewMode = Utils.parseViewMode(baseApi.window.viewMode);
    const baseUrl = Utils.getBaseUrl(baseApi, viewMode);
    return fetch(`${baseUrl}/_api/pro-gallery-webapp/v1/viewer/experiments`, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => res.experiments)
      .catch(() => {
        return {};
      });
  }

  static getExperimentsAndInitWrapper = (function () {
    let executed = false;
    return function () {
      if (!executed) {
        executed = true;
        return (baseApi, report) => {
          Utils.getExperiments(baseApi)
            .then((experimentsRaw) => {
              experimentsWrapper.setExperiments(experimentsRaw);
            })
            .catch((e) => {
              report && report(e);
              console.error('Waiting for experimentsPromise failed', e);
            });
        };
      } else {
        return () => {};
      }
    };
  })();

  static parseViewMode(viewMode) {
    //
    switch (viewMode.toLowerCase()) {
      case 'editor':
        return GALLERY_CONSTS.viewMode.EDIT;
      case 'preview':
        return GALLERY_CONSTS.viewMode.PREVIEW;
      case 'site':
      default:
        return GALLERY_CONSTS.viewMode.SITE;
    }
  }

  static parseFormFactor(formFactor) {
    switch (formFactor.toLowerCase()) {
      case 'tablet':
        return GALLERY_CONSTS.formFactor.TABLET;
      case 'mobile':
        return GALLERY_CONSTS.formFactor.MOBILE;
      case 'desktop':
      default:
        return GALLERY_CONSTS.formFactor.DESKTOP;
    }
  }

  static async verifyExperiments(baseApi) {
    if (experimentsWrapper.experimentsAreReady) {
      return;
    } else {
      baseApi && this.getExperimentsAndInitWrapper()(baseApi);
      return new Promise((resolve) => {
        experimentsWrapper.onExperimentsReady((response) => resolve(response));
      });
    }
  }

  isVerbose() {
    return (
      !this.isTest() && (this.safeLocalStorage() || {}).forceDevMode === 'true'
    );
  }

  isTest() {
    try {
      return window.isTest;
    } catch (e) {
      return false;
    }
  }

  safeLocalStorage() {
    try {
      return localStorage ? localStorage : window; // TrackJS errors, function returning null
    } catch (e) {
      return window;
    }
  }

  isDev() {
    return (
      this.isLocal() ||
      process.env.NODE_ENV === 'development' ||
      (this.safeLocalStorage() || {}).forceDevMode === 'true'
    );
  }

  isLocal() {
    const ipRegex = /([0-9]{1,3}\.){3}[0-9]{1,3}:[0-9]{1,5}/; // matches 111.222.333.444:9999
    const host = window.location.hostname || '';
    const isLocal =
      host === 'local.wix.com' ||
      host === '0.0.0.0' ||
      host.indexOf('localhost') >= 0 ||
      ipRegex.exec(host) !== null;
    return isLocal;
  }

  isFormFactorMobile(formFactor) {
    // santa options: mobile/desktop
    // bolt options: smartphone/desktop/tablet
    const deviceType = String(formFactor).toLowerCase();
    return deviceType === 'mobile' || deviceType === 'smartphone';
  }

  isMobile(formFactor) {
    return this.isFormFactorMobile(formFactor);
  }

  isDimensionless(item) {
    try {
      if (!item.metaData.height || !item.metaData.width) {
        return true;
      }
      if (item.metaData.height <= 1 || item.metaData.width <= 1) {
        return true;
      }
    } catch (e) {
      console.error('corrupt item, cant check for dimensions', item, e);
    }
    return false;
  }
}

export const utils = new Utils();
